<template>
    <!-- 邀请记录 移动端 -->
    <div class="invite_record">
        <div class="item" v-for="(item,index) in inviteList" :key="index">
            <div class="account">{{item.account}}</div>
            <div class="row">
                <div class="title">{{$t('invite.money')}}</div>
                <div class="dollar">{{item.num}} USDT</div>
            </div>
            <div class="row">
                <div class="title">{{$t('invite.pay')}}</div>
                <div class="time">{{item.time}}</div>
            </div>
        </div>
    </div>
</template>
<style lang="stylus" scoped>
	@import 'inviteRecord.styl';
</style>
<script>
import { Loading } from 'element-ui';
import { mapGetters } from 'vuex';
// api
import {getInvite} from '@/api/invite'

export default {
    data(){
        return{
            inviteList:[],
        }
    },
    created(){
        if(this.isMobile==1){
            const loading = Loading.service({
                target: '.main',
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)',
                customClass: 'apploading',
            });
            getInvite({account:this.account}).then(res=>{
                this.inviteList=res.list;

                loading.close();
            }).catch(err=>{
                loading.close();
            })
        }else{
            this.$router.push({
                path:'/invite'
            })
        }
    },
    computed:{
        ...mapGetters(['account','isMobile']),
    },
}
</script>
